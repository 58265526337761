import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

export default function ProtectedRoute({ children }) {
  const { isAuthenticated } = useAuth();
  const [showLoading, setShowLoading] = useState(true);

  // Debug log to track authentication state
  useEffect(() => {
    console.log("ProtectedRoute - Authentication State:", isAuthenticated);

    // Add a timeout to avoid flickering between states
    if (isAuthenticated === null) {
      const timer = setTimeout(() => {
        setShowLoading(false);
      }, 3000); // Show loading for max 3 seconds

      return () => clearTimeout(timer);
    } else {
      setShowLoading(false);
    }
  }, [isAuthenticated]);

  // If we're still checking authentication and within the loading time window
  if (isAuthenticated === null && showLoading) {
    console.log("ProtectedRoute - In loading state");
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column'
      }}>
        <h3>Checking authentication...</h3>
        <p>Please wait while we verify your credentials.</p>
      </div>
    );
  }

  // If auth is null but we've waited too long, or if explicitly not authenticated
  if (isAuthenticated === null || !isAuthenticated) {
    // Not authenticated or taking too long, redirect to homepage
    console.log("ProtectedRoute - Not authenticated or timeout, redirecting");
    return <Navigate to="/" replace />;
  }

  // User is authenticated, render the children
  console.log("ProtectedRoute - Authenticated, showing protected content");
  return children;
}
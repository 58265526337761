/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { ApiClient } from "../../ApiClient";
import { Button, Container, Alert, Card, Row, Col, Badge } from "react-bootstrap";
import Modal from "react-modal";
import Wizard from "../wizard/Wizard";
import Config from "../../Config";
import { useAuth } from "../../AuthContext";
import "../../index.css";

export default function Settings() {
  Modal.setAppElement("#root");
  const [modalIsOpen, setIsOpen] = useState(false);
  const apiClient = new ApiClient();
  const { isAuthenticated } = useAuth(); // Get auth state from context
  const [userSettings, setUserSettings] = useState({
    'calendar_name': { 'S': 'Collecting...' },
    'club_name': { 'S': 'Collecting...' },
    'first_name': { 'S': 'Collecting...' },
    'last_name': { 'S': 'Collecting...' },
    'last_strava_update': { 'N': '0' }
  });
  const [calendarList, setCalendarList] = useState([]);
  const [stravaClubList, setStravaClubList] = useState([]);
  const [isGoogleAuthenticated, setIsGoogleAuthenticated] = useState(false);
  const [isCheckingGoogleAuth, setIsCheckingGoogleAuth] = useState(true);
  const [settingsError, setSettingsError] = useState(null);

  useEffect(() => {
    console.log("Settings - Component mounted, isAuthenticated:", isAuthenticated);

    // Get user settings
    apiClient.getSettings().then((settings) => {
      console.log("User settings result", settings);
      setUserSettings(settings.data);
    }).catch(error => {
      console.error("Error fetching settings:", error);
      setSettingsError("Failed to load user settings. Please try again.");
    });

    // Check Google authentication by trying to fetch calendars
    checkGoogleAuthentication();
  }, []);

  const checkGoogleAuthentication = () => {
    setIsCheckingGoogleAuth(true);
    apiClient.googleCal()
      .then((result) => {
        console.log("Google calendars result:", result);
        if (result.data && result.data.length > 0) {
          setCalendarList(result.data);
          setIsGoogleAuthenticated(true);
        } else {
          setIsGoogleAuthenticated(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching Google calendars:", error);
        setIsGoogleAuthenticated(false);
      })
      .finally(() => {
        setIsCheckingGoogleAuth(false);
      });
  };

  const customStylesModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "70%",
      maxWidth: "800px",
      maxHeight: "90vh",
      overflow: "auto",
      marginRight: "-50%",
      padding: "30px",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      border: "1px solid #ddd",
      position: "relative"
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.75)"
    },
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "10px",
    right: "15px",
    background: "none",
    border: "none",
    fontSize: "22px",
    fontWeight: "bold",
    cursor: "pointer",
    color: "#666",
    padding: "0",
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    transition: "background-color 0.2s ease"
  };

  const openModal = () => {
    // Check if user is authenticated with Google before opening the wizard
    if (!isGoogleAuthenticated) {
      // Redirect to Google auth if not authenticated
      window.location.href = apiClient.getAuthUrl('google');
      return;
    }

    // If authenticated, open the wizard
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    refreshStravaClub();
    // No need to refresh calendars if we already have them
    if (calendarList.length === 0) {
      refreshCalendars();
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const refreshCalendars = () => {
    apiClient.googleCal().then((result) => {
      console.log("googleCal result", result);
      if (result.data && result.data.length > 0) {
        setCalendarList(result.data);
        setIsGoogleAuthenticated(true);
      }
    }).catch(error => {
      console.error("Error refreshing calendars:", error);
      setIsGoogleAuthenticated(false);
    });
  };

  const refreshStravaClub = () => {
    apiClient.stravaClub().then((result) => {
      console.log("stravaClub result", result);
      setStravaClubList(result.data);
    });
  };

  // Format date for more readable display
  const formatSyncDate = (timestampStr) => {
    if (!timestampStr || parseInt(timestampStr) === 0) return 'No events synced yet';

    try {
      // Convert epoch timestamp to milliseconds if needed
      const timestamp = parseInt(timestampStr);
      // Check if timestamp is in seconds (10 digits) or milliseconds (13 digits)
      const date = new Date(timestamp < 10000000000 ? timestamp * 1000 : timestamp);

      return new Intl.DateTimeFormat('en-US', {
        dateStyle: 'medium',
        timeStyle: 'short'
      }).format(date);
    } catch (e) {
      console.error("Error formatting date:", e);
      return 'Invalid date';
    }
  };

  return (
    <>
      <Container className="py-4">
        <div className="content-container">
          <div className="d-flex align-items-center mb-4">
            <h3 className="mb-0 me-2">⚙️ Settings</h3>
            {isGoogleAuthenticated && (
              <Badge bg="success" pill className="ms-2">Google Connected</Badge>
            )}
          </div>

          {/* Debug info - only show in local development */}
          {Config.isLocalDev && (
            <div style={{ backgroundColor: '#f8f9fa', padding: '10px', marginBottom: '20px', border: '1px solid #ddd', borderRadius: '5px' }}>
              <p><strong>Debug Info:</strong></p>
              <p>Auth Context - isAuthenticated: {isAuthenticated === null ? 'checking...' : isAuthenticated ? 'true' : 'false'}</p>
              <p>Google Calendar Connected: {isCheckingGoogleAuth ? 'checking...' : isGoogleAuthenticated ? 'true' : 'false'}</p>
              {settingsError && <p style={{ color: 'red' }}>Error: {settingsError}</p>}
            </div>
          )}

          {userSettings.calendar_name && userSettings.club_name ? (
            <>
              {userSettings.calendar_name.S === "Collecting..." || userSettings.club_name.S === "Collecting..." ? (
                <Card className="mb-4 border-0 shadow-sm">
                  <Card.Body className="text-center p-5">
                    <div className="spinner-border text-primary mb-3" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <h5 className="mb-0">Collecting settings...</h5>
                  </Card.Body>
                </Card>
              ) : (
                <>
                  <Card className="mb-4 border-0 shadow-sm">
                    <Card.Body>
                      <h4 className="mb-3">
                        Welcome, {userSettings.first_name.S} {userSettings.last_name.S} 👋
                      </h4>
                      <p className="lead">
                        Use this settings page to configure the Strava Club to Google Calendar relationship. Once configured, the club events will be synced to the Google Calendar.
                      </p>
                    </Card.Body>
                  </Card>

                  <Card className="mb-4 border-0 shadow-sm">
                    <Card.Header className="bg-light py-2 px-3">
                      <h5 className="mb-0">Current Configuration</h5>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col md={6} className="mb-3 mb-md-0">
                          <Card className="h-100 border-0 bg-light">
                            <Card.Body>
                              <div className="d-flex align-items-center mb-2">
                                <span className="me-2" style={{ fontSize: "1.25rem" }}>🗓️</span>
                                <h6 className="mb-0 fw-bold">Google Calendar</h6>
                              </div>
                              <p className="mb-0">{userSettings.calendar_name.S}</p>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card className="h-100 border-0 bg-light">
                            <Card.Body>
                              <div className="d-flex align-items-center mb-2">
                                <span className="me-2" style={{ fontSize: "1.25rem" }}>🚴</span>
                                <h6 className="mb-0 fw-bold">Strava Club</h6>
                              </div>
                              <p className="mb-0">{userSettings.club_name.S}</p>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>

                      <div className="mt-3 pt-3 border-top">
                        <div className="d-flex align-items-center">
                          <span className="me-2" style={{ fontSize: "1.25rem" }}>🔄</span>
                          <div>
                            <h6 className="mb-0 fw-bold">Last Sync</h6>
                            <p className="mb-0 text-muted small">
                              {formatSyncDate(userSettings.last_strava_update?.N)}
                              {(!userSettings.last_strava_update?.N || parseInt(userSettings.last_strava_update?.N) === 0) &&
                                " - Events will sync automatically within an hour"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </>
              )}
            </>
          ) : (
            <Card className="mb-4 border-0 shadow-sm">
              <Card.Body className="text-center p-4">
                <h5 className="mb-3">No Settings Found</h5>
                <p>Please run the setup wizard to configure your Strava and Google Calendar integration.</p>
              </Card.Body>
            </Card>
          )}

          {!isGoogleAuthenticated && (
            <Alert variant="info" className="border-0 shadow-sm">
              <div className="d-flex align-items-center">
                <div className="me-3">
                  <i className="bi bi-info-circle-fill" style={{ fontSize: "1.5rem" }}></i>
                </div>
                <div>
                  <p className="mb-2">You need to connect to Google Calendar before running the setup wizard:</p>
                  <a href={apiClient.getAuthUrl('google')} className="btn btn-light">
                    <img
                      alt=""
                      src="/google.png"
                      height="24"
                      className="d-inline-block align-middle me-2"
                    />
                    Connect with Google
                  </a>
                </div>
              </div>
            </Alert>
          )}

          <div className="text-center mt-4 mb-5">
            <Button
              className="py-2 px-4"
              id="open-modal"
              variant="primary"
              onClick={openModal}
              disabled={!isGoogleAuthenticated || isCheckingGoogleAuth}
              style={{
                backgroundColor: "rgb(255,113,0)",
                border: "none",
                borderRadius: "50px",
                fontWeight: "600"
              }}
            >
              {isCheckingGoogleAuth ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  Checking...
                </>
              ) : (
                <>Run Setup Wizard</>
              )}
            </Button>
          </div>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStylesModal}
          contentLabel="Wizard Modal"
          shouldCloseOnOverlayClick={false}
        >
          <button
            onClick={closeModal}
            style={closeButtonStyle}
            aria-label="Close"
            title="Close"
          >
            ×
          </button>
          <h3 className="mb-4">Setup Wizard</h3>
          <Wizard calendarList={calendarList} stravaClubList={stravaClubList} />
        </Modal>
      </Container>
    </>
  );
}

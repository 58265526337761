import React, { createContext, useState, useContext, useEffect, useMemo } from 'react';
import { ApiClient } from './ApiClient';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const apiClient = useMemo(() => new ApiClient(), []);

  useEffect(() => {
    const checkAuthentication = async () => {
      console.log("AuthContext - Starting authentication check");
      try {
        const response = await apiClient.authCheck();
        console.log("AuthContext - Auth check response:", response.data);
        setIsAuthenticated(response.data.isAuthenticated);
      } catch (error) {
        console.error('Authentication check failed:', error);
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, [apiClient]);

  // Debug log when auth state changes
  useEffect(() => {
    console.log("AuthContext - isAuthenticated state:", isAuthenticated);
  }, [isAuthenticated]);

  const handleLogout = async () => {
    await apiClient.logOut();
    setIsAuthenticated(false);
    window.location.href = "/";
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);